<template>
  <div class="n-login">
    <div class="n-login__media">
      <div class="img-box">
        <img 
          src="/img/nlogin.jpg"
          alt="">
      </div>
      <div class="text-box">
        <div>
          <div class="title">Accédez à Vos Factures en Toute Simplicité!</div>
          <p>Simplifiez votre processus de facturation avec notre outil convivial. Accédez à vos factures en toute simplicité et gérez vos finances avec une efficacité accrue.</p>
          <a href="https://tecbill.net" class="btn btn-primary" style="font-weight: 500;"> Créer mon compte </a>
        </div>
      </div>
    </div>
    <div class="n-login__content">
      <div class="n-header">
        <div class="n-header__right"></div>
        <div class="n-header__left"></div>
      </div>
      <div class="n-content">
        <form @submit.prevent="login" class="n-content__form">
          <div class="pb-2 welcomeback">Bienvenue</div>
          <p style="font-weight: 400;">Votre expérience commence ici, connectez-vous dès maintenant et explorez</p>
          <div class="form-group mt-5">
            <mdb-input
              v-model="credential.email"
              size="lg"
              type="text"
              label="Adresse mail"
              placeholder="johndoe@gmail.com"
              outline
            ></mdb-input>
          </div>
          <div class="form-group mt-4">
            <mdb-input
              v-model="credential.password"
              size="lg"
              :type="'password'"
              label="Mot de passe"
              placeholder="******"
              outline
            ></mdb-input>
          </div>
          <div class="form-group mt-4">
            <a class="miniaction" href="/forgot-password">Mot de passe oublié !</a>
          </div>
          <div class="form-group mt-4">
            <mdb-btn :disabled="loginBtn" color="primary"  type="submit" style="font-weight: 500;">
              <span v-if="!loginBtn">Se connecter</span>
              <span
                v-if="loginBtn"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span v-if="loginBtn" class="pl-2">Loading...</span>
            </mdb-btn>
          </div>
        </form>
      </div>
      <div class="n-footer">
        <span class="ft">Propulsé par <a href="https://www.tec-sarl.com">TEC-SARL</a></span>
      </div>
    </div>
  </div>
</template>
<script>
import { mdbInput, mdbBtn } from "mdbvue";
import config from '../../../tstba.conf'
export default {
  name: "login",
  metaInfo: {
    title: "TECBILL APP",
    titleTemplate: "%s - LOGIN",
    htmlAttrs: {
      lang: "fr",
      amp: true,
    },
  },
  components: {
    mdbInput,
    mdbBtn
  },
  data() {
    return {
      pwdseen: false,
      loginBtn: false,
      credential: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    async login() {
      if (!this.credential.email.trim() || !this.credential.password.trim()) {
        this.$notify({
          message: "Les champs sont requis.",
          type: "danger",
        });
        return;
      }
      this.loginBtn = !this.loginBtn;
      await this.$store
        .dispatch("auth/login", this.credential)
        .then(() => {
          this.loginBtn = !this.loginBtn;

          if (this.$route.query.intended === 'tbasp') {
            location.replace(config.production_tip ? config.tba_sp_prod : config.tba_sp_dev)
          }else{
            this.$router.replace({
              path: "/",
            });
          }
        })
        .catch((error) => {
          this.loginBtn = !this.loginBtn;
          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },
  },
};
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;0,800;0,900;1,300;1,400;1,500;1,700;1,800;1,900&display=swap');
.n-login{
  display: grid;
  grid-template-columns: 450px 1fr;
  background: #fff;
  width: 100%;
  height: 100vh;
}

.n-login .n-login__media
{
  display: block;
  background: #000;
}

.n-login .n-login__media .img-box
{
  position: relative;
  width: 100%;
  height: 100% ;

  img{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &::before{ 
    content: '';
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: rgba(0, 0, 0, 0.6);
    mix-blend-mode: normal;
}
}

.n-login .n-login__media .text-box{
  width:450px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  padding-inline: 20px;
  z-index: 15;

  text-align: center;
  .title{
    font-size: 1.85rem;
    color: #fff !important;
    font-weight: 900;
    font-family: 'Ubuntu','Roboto', sans-serif;
  }

  .title ~ p{
    margin-top: 20px;
    font-size: 1.005rem;
    color: #fff;
    font-family: 'Ubuntu','Roboto', sans-serif;
    font-weight: 400;
  }
}
.n-login .n-login__content{
  display: flex;
  flex-direction: column;
  align-items: center;
  
  width: 100%;
}

.n-content {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.n-header {
  width: 100%;
  height: 100px;
}

.n-footer {
  padding-inline: 40px;
  width: 100%;
  height: 100px;
}

.n-footer .ft {
  font-weight: 500;
  font-family: 'Ubuntu';
  a{
    color: var(--l-blue);
  }
}

.n-content__form{
  width: 400px;
}

.welcomeback{
  text-align: left;
  font-family: 'Ubuntu', 'Roboto', sans-serif;
  color: var(--l-blue);
  font-weight: 500;
  font-size: 1.7rem;
}

.miniaction{
  color: var(--l-blue);
  font-size: 1.05em;
  font-family: 'Ubuntu';
  font-weight: 400;
  display: inline-block;
  text-align:right;
  width: 100%;

  &:hover{
    color: var(--l-blue);
    text-decoration: underline;
  }
}

@media(max-width:768px){
  .n-login{
    grid-template-columns: 1fr;
  }

  .n-login .n-login__media
  {
    display: none;
  }

  .n-content__form{
    width: 100%;
    padding-inline: 40px;
  }
}

</style>

